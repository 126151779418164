import React from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { SettingsContext } from '../../hoc/Settings';
import { EditorContext } from '../../hoc/Editor';
import { RouteContext } from '../../hoc/Route';
import { UserContext } from '../../hoc/User';
import { LocalizationContext } from '../../hoc/Localization';
import Header from './Header';
import Router from '../../routes';
import Button from '../Button';
import { getCookie, setCookie } from '../../utils/cookies';
import styles from './styles.less';
import { RegisterFlowTypesEnum } from '../RegisterFlow/enums/TypesEnum';
import { RegisterFlowStepGroupsEnum } from '../RegisterFlow/enums/StepGroupsEnum';
import { UtmContents } from '../../enums';

const Footer = dynamic(() => import('./Footer'));
const Loading = dynamic(() => import('../Loading'));
const RegisterFlow = dynamic(() => import('../RegisterFlow'));

const Layout = ({ children }) => {
  const {
    domainSettings: {
      indexation,
      facebook: { appId, pageId },
      landingUrl,
      GDPREnabled,
    },
    baseUrl
  } = React.useContext(SettingsContext);
  const { user } = React.useContext(UserContext);
  const { page, loading, queryParams } = React.useContext(RouteContext);
  const { setEditMode } = React.useContext(EditorContext);
  const { t } = React.useContext(LocalizationContext);
  const [cookiesAccepted, setCookiesAccepted] = React.useState(true);

  const isBlog = ['article', 'about', 'terms', 'blogLanding', 'addCourseIntro'].includes(page);
  const isCoursePayment = page === 'courseShow' && !!queryParams.payment;
  const isBonusShare = queryParams.utm_content === UtmContents.bonus_share;

  let obImage = landingUrl;
  let obTitle;

  if (isBonusShare) {
    obImage = `${baseUrl}/static/images/modal/register-flow/bonus-share-preview.png`;
    obTitle = t('bonus.share.preview.title')
  }

  React.useEffect(() => {
    if (!user) {
      setCookiesAccepted(getCookie('cookiesAccepted'));
    }
    if (['orders', 'rewards'].includes(page) && !user) {
      Router.pushRoute('index');
    }
  }, [user]);

  React.useEffect(() => {
    if (!['createSchool', 'createCourse', 'school', 'course', 'createCourse'].includes(page)) {
      setEditMode(false);
    }
  }, [page]);

  const links = [];

  if (isBlog) {
    links.push(<link href="/static/css/blog.css" rel="stylesheet" key="blog-style" />);
  }

  function handleCookieAccept() {
    setCookie('cookiesAccepted', true);
    setCookiesAccepted(true);
  }

  return (
    <>
      <Head>
        <script src="https://apis.google.com/js/platform.js" async defer />
        {!indexation && <meta name="robots" content="noindex,nofollow" />}
        {links}
        {['createCourseSchool', 'createSchool', 'course', 'school'].includes(page) && (
          <script
            src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDbKk-50AIP9uibWPXjtYj0IPYsJGaj5iQ&libraries=places&language=en"
            defer
            async
          />
        )}
        {!['school', 'schoolShow', 'course', 'article', 'landing', 'courseShow'].includes(page) && (<>
          <meta property="og:image" content={obImage} />
          {!!obTitle && <meta property="og:title" content={obTitle} />}
        </>)}
        <script src="https://js.stripe.com/v3/" />
        <script dangerouslySetInnerHTML={{
          __html: `
            window.fbAsyncInit = function() {
              window.FB.init({
                appId: '${appId.toString()}',
                pageId: '${pageId.toString()}',
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v10.0'
              })
            }
        `}} />
        <script async defer crossorigin="anonymous" id="facebook-jssdk" src="https://connect.facebook.net/en_US/sdk.js" />
      </Head>
      {!user && GDPREnabled && !cookiesAccepted && (
        <div className={styles.cookieContainer}>
          <div className={styles.cookieContent}>
            <div className={styles.imageText}>
              <img
                src="https://d29fhpw069ctt2.cloudfront.net/icon/image/85060/preview.svg"
                alt="cookie"
              />
              <p>{t('cookie.text')}</p>
            </div>
            <Button onClick={handleCookieAccept} type="primary">
              {t('cookie.ok.text')}
            </Button>
          </div>
        </div>
      )}
      <div
        className={[
          page !== 'createCourseSchool' && styles.layout,
          page !== 'index' && page !== 'createCourseSchool' ? styles.headerSearch : null,
          isCoursePayment ? styles.noHeader : null,
        ].join(' ')}
      >
        <div id="fb-root" />
        {!isCoursePayment && <Header />}
        {!isCoursePayment && <RegisterFlow initVisible={true} />}
        <div id="container" className={[styles.content, isBlog ? 'blog-page' : null].join(' ')}>
          {loading && (
            <div className="editor-saving">
              <Loading full />
            </div>
          )}
          {children}
        </div>

        {!['search'].includes(page) && !isCoursePayment && <Footer />}
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

Layout.defaultProps = {
  children: null,
};

export default Layout;
