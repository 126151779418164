import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import bugsnagClient from '../utils/bugsnag';
import Link from '../components/Link';
import Button from '../components/Button';
import styles from './styles.less';

import { LocalizationContext } from '../hoc/Localization';

const Error = ({ title, description, text, button }) => {
  if (LocalizationContext) {
    const { t, locale } = React.useContext(LocalizationContext);

    return (
      <div className={styles.wrapper}>
        <Head>
          <title>{`${t('meta.title.suffix')} | ${t('notfound.title')}`}</title>
        </Head>
        <div className={styles.errorPage}>
          <div className={styles.inner}>
            <h1>{title || t('notfound.title')}</h1>
            <h2>{description || t('notfound.desc')}</h2>
            <p>{text || t('notfound.text')}</p>
            {button || (
              <Link route="index" locale={locale} className={styles.notFoundHome}>
                <Button size="default" type="primary">
                  {t('notfound.home')}
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.wrapper}>
      <Head>
        <title>Course Finder | 404</title>
      </Head>
      <div className={styles.errorPage}>
        <div className={styles.inner}>
          <h1>{title || 404}</h1>
          <h2>{description || "Oops, sorry we can't find that page!"}</h2>
          <p>{text || "Either something went wrong or the page doesn't exist anymore."}</p>
          {button || (
            <Link route="index" locale="en">
              <Button size="default" type="primary">
                Home page
              </Button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

Error.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  text: PropTypes.string,
  button: PropTypes.any,
};

Error.defaultProps = {
  title: null,
  description: null,
  text: null,
  button: null,
};

Error.getInitialProps = ({ req, res, err }) => {
  if (err) {
    bugsnagClient.notify(err, {
      request: req,
    });

    console.error('Error: ', err);
  }

  res.statusCode = 404;
  return {};
};

export default Error;
