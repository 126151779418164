import React from 'react';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import { RouteContext } from './Route';
import api from '../utils/api';
import { LocalizationContext } from './Localization';
import { LocationContext } from './Location';
import { SettingsContext } from './Settings';
import Router from '../routes';
import { UserContext } from './User';
import { getCourseByShortId, getSchoolByShortId } from '../utils/directory';
import { shortId } from '../utils/index';

export const EditorContext = React.createContext();

export const EditorProvider = ({ children }) => {
  const [content, setContentDefault] = React.useState(null);
  const [edited, setEditedDefault] = React.useState({});
  const [editMode, setEditMode] = React.useState(false);
  const [locale, setLocale] = React.useState('default');
  const [saving, setSaving] = React.useState(null);
  const [type, setType] = React.useState('');
  const [footer, setFooter] = React.useState(false);

  const { locale: pageLocale, t } = React.useContext(LocalizationContext);
  const {
    domainSettings: { timezone, schoolId, onboardingCourseId },
  } = React.useContext(SettingsContext);
  const { refreshUser } = React.useContext(UserContext);
  const { page } = React.useContext(RouteContext);
  const { locations } = React.useContext(LocationContext);

  const countries = locations
    .filter(location => location.default)
    .map(location => location.country);

  const requirements = {
    course: {
      title: {
        validIfOneOf: ['title_hy', 'title_en', 'title_de', 'title_ru'],
        message: t('course.edit.title.warning'),
      },
      trainingType: {
        message: t('course.edit.trainingType.warning'),
      },
      image: {
        message: t('course.edit.image.warning'),
      },
      category: {
        message: t('course.edit.category.warning'),
      },
      coursetypes: {
        message: t('course.edit.coursetype.warning'),
      },
      language: {
        message: t('course.edit.subject.warning'),
      },
      location: {
        message: t('course.edit.location.warning'),
        condition: ({ location }) =>
          !!(location && location.lat && location.lng && location.house_number),
      },
    },
    createCourse: {
      title: {
        validIfOneOf: ['title_hy', 'title_en', 'title_de', 'title_ru'],
        message: t('course.edit.title.warning'),
      },
      trainingType: {
        message: t('course.edit.trainingType.warning'),
      },
      image: {
        message: t('course.edit.image.warning'),
      },
      category: {
        message: t('course.edit.category.warning'),
      },
      coursetypes: {
        message: t('course.edit.coursetype.warning'),
      },
      language: {
        message: t('course.edit.subject.warning'),
      },
      location: {
        message: t('course.edit.location.warning'),
        condition: ({ location }) =>
          !!(location && location.lat && location.lng && location.house_number),
      },
    },
    school: {
      title: {
        message: t('school.edit.title.warning'),
      },
      logo: {
        message: t('school.edit.logo.warning'),
        condition: data => !!(data.logo && data.logo !== '/static/images/school/defaultLogo.svg'),
      },
      cover_photo: {
        message: t('school.edit.cover.warning'),
      },
      description: {
        validIfOneOf: ['description_hy', 'description_en', 'description_de', 'description_ru'],
        message: t('school.edit.description.warning'),
      },
      location: {
        message: t('school.edit.address.warning'),
        condition: ({ location }) =>
          !!(location && location.lat && location.lng && location.house_number),
      },
      email: {
        message: t('school.edit.schoolEmail.warning'),
        condition: data => {
          const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return regex.test(String(data.email).toLowerCase());
        },
      },
    },
    createSchool: {
      title: {
        message: t('school.edit.title.warning'),
      },
      logo: {
        message: t('school.edit.logo.warning'),
        condition: data => !!(data.logo && data.logo !== '/static/images/school/defaultLogo.svg'),
      },
      cover_photo: {
        message: t('school.edit.cover.warning'),
      },
      description: {
        validIfOneOf: ['description_hy', 'description_en', 'description_de', 'description_ru'],
        message: t('school.edit.description.warning'),
      },
      location: {
        message: t('school.edit.address.warning'),
        condition: ({ location }) =>
          !!(location && location.lat && location.lng && location.house_number),
      },
      email: {
        message: t('school.edit.schoolEmail.warning'),
        condition: data => {
          const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return regex.test(String(data.email).toLowerCase());
        },
      },
    },
  };

  const defineContent = value => {
    setContentDefault(value);
    setEditedDefault(value);
  };

  const updateContent = value => {
    setEditedDefault(value);
  };

  const validateFields = () =>
    !Object.keys(requirements[type])
      .map(key => {
        const requirement = requirements[type][key];

        if (!edited[key] && requirement.validIfOneOf) {
          if (!requirement.validIfOneOf.map(valid => !!edited[valid]).includes(true)) {
            notification.error({
              message: 'Warning',
              description: requirement.message,
            });

            return false;
          }

          return true;
        }

        if (requirement.condition && !requirement.condition(edited)) {
          notification.error({
            message: 'Warning',
            description: requirement.message,
          });

          return false;
        }

        if (edited[key]) {
          return true;
        }

        notification.error({
          message: 'Warning',
          description: requirement.message,
        });

        return false;
      })
      .includes(false);

  const saveContent = async copy => {
    if (saving) return;
    const valid = validateFields();
    edited.draft = !valid;
    const isCopy = copy === 'copy';

    if (valid) {
      setSaving(true);
    } else if (edited.id && !edited.draft) {
      return;
    }

    let url;
    let method;
    let data = {};

    if (type === 'course') {
      data = {
        course: {
          ...edited,
          schoolId,
          shortId: isCopy ? shortId() : edited.shortId,
          external_id: isCopy ? undefined : edited.external_id,
          title: edited.title || edited.title_en || edited.title_hy || edited.title_de,
          description:
            edited.description ||
            edited.description_en ||
            edited.description_hy ||
            edited.description_de,
          school: {
            id: edited.school.id,
            shortId: edited.school.shortId,
          },
          category: edited.category
            ? {
              ...edited.category,
              _id: edited.category.id,
            }
            : null,
        },
      };
    } else {
      data = { school: { timezone, ...edited, schoolId }, onboardingCourseId };
    }

    if (page === 'school' || page === 'createSchool') {
      url = `schools/${edited.shortId}/update`;
      method = 'put';
    } else if (page === 'course' || page === 'createCourse') {
      url = `courses/${edited.shortId}/update`;
      method = 'put';
    }

    if (valid) {
      await api({
        method,
        data,
        url,
      })
        .then(async res => {
          if (page === 'createCourse') {
            const { course } = res;

            setSaving(true);
            setTimeout(() => {
              setSaving(false);
              setEditMode(false);
              Router.pushRoute('courseShow', {
                locale: pageLocale,
                course: 'created',
                school: course.school.shortId,
                id: course.shortId,
                reference: 'createCourse',
              });
              setEditedDefault({});
            }, 5000);

            if (window.fbq) {
              window.fbq('trackCustom', 'ResourceCreated', { type: 'course' });
            }
          }

          if (page === 'createSchool') {
            const { school } = res;

            if (valid) {
              setSaving(true);

              await refreshUser();

              Router.pushRoute('schoolShow', {
                locale: pageLocale,
                school: 'created',
                id: school.shortId,
              });
              setEditedDefault({});
              setEditMode(false);
              setSaving(false);

              if (window.fbq) {
                window.fbq('trackCustom', 'ResourceCreated', { type: 'school' });
              }
            }
          }

          if (page === 'course') {
            const { course } = res;

            const fetchedCourse = await getCourseByShortId(course.shortId, { countries });

            setEditedDefault(fetchedCourse.course);
            if (valid) {
              setEditMode(false);
              setSaving(false);
              Router.pushRoute('courseShow', {
                locale: pageLocale,
                course: edited.link,
                school: edited.school.link,
                id: course.shortId,
                reference: 'updateCourse',
              });
            }

            if (copy === 'copy') {
              setTimeout(() => {
                setSaving(false);
                setEditMode(false);
                Router.pushRoute('courseShow', {
                  locale: pageLocale,
                  course: edited.link,
                  school: edited.school.link,
                  id: course.shortId,
                });
              }, 5000);
            }
            setEditedDefault({});
          }

          if (page === 'school') {
            const { school } = res;
            const fetchedSchool = await getSchoolByShortId(school.shortId, {});
            setEditedDefault(fetchedSchool);

            if (valid) {
              setEditMode(false);
              setSaving(false);
              Router.pushRoute('schoolShow', {
                locale: pageLocale,
                school: fetchedSchool.link,
                id: fetchedSchool.shortId,
              });
              setEditedDefault({});
            }
          }

          return null;
        })
        .catch(async () => {
          if (valid) {
            setEditMode(false);
            setSaving(false);
          }
        });
    }
  };

  return (
    <EditorContext.Provider
      value={{
        type,
        edited,
        saving,
        locale,
        content,
        setType,
        editMode,
        setLocale,
        setEditMode,
        saveContent,
        requirements,
        defineContent,
        updateContent,
        footer,
        setFooter,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};

EditorProvider.propTypes = {
  children: PropTypes.any,
};

EditorProvider.defaultProps = {
  children: null,
};

EditorProvider.getInitialProps = async () => ({});
