import React from 'react';
import ReactGA from 'react-ga';
import { RouteContext } from './Route';

ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID);

const Analytics = ({ children }) => {
  const { page, params, queryParams } = React.useContext(RouteContext);

  React.useEffect(() => {
    const pageView = () => {
      ReactGA.pageview(window.location.pathname + window.location.search);
    };

    pageView();
  }, [page, params, queryParams]);

  return children;
};

Analytics.getInitialProps = async () => ({});

export default Analytics;
