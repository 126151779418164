import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { Layout, Drawer, Popover, Avatar } from 'antd';
import { LocalizationContext } from '../../../hoc/Localization';
import { SettingsContext } from '../../../hoc/Settings';
import { RouteContext } from '../../../hoc/Route';
import { UserContext } from '../../../hoc/User';
import { SecurityContext } from '../../../hoc/Security';
import Link from '../../Link';
import styles from './styles.less';
import Router from '../../../routes';
import NavBar from './NavBar';

const Search = dynamic(() => import('../../CourseSearch'));
const Profile = dynamic(() => import('../Profile'));
const MySchool = dynamic(() => import('./MySchool'));

const { Header } = Layout;

const CustomHeader = () => {
  const { page } = React.useContext(RouteContext);
  const {
    domainSettings: { logoUrl, domain },
  } = React.useContext(SettingsContext);

  const [transparent, setTransparent] = React.useState(true);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [isSelectedInput, setSelectedInput] = React.useState(false);

  const fixed = false;

  const handleScroll = () => {
    const scroll = (window.pageYOffset || window.scrollTop || 0) - (window.clientTop || 0);
    const height = window.outerHeight;
    const isTransparent = scroll < height / 3;

    if (isTransparent !== transparent) {
      setTransparent(isTransparent);
    }
  };

  if (process.browser && fixed) {
    window.removeEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll);

    setTimeout(() => {
      handleScroll();
    });
  }

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const closeModal = () => {
    setDrawerOpen(false);
  };
  function handleFocusChange(focus) {
    setSelectedInput(focus);
  }
  function transitionLogo() {
    if (isSelectedInput) {
      return 'transitionLogo';
    }
    return 'smallLogo';
  }
  function transitionDrawerButton() {
    if (isSelectedInput) {
      return 'transitionDrawerButton';
    }
    return 'drawerButton';
  }

  return (
    <>
      <Drawer
        className={[styles.drawer, page !== 'index' ? styles.headerSearch : null].join(' ')}
        placement="left"
        closable={false}
        onClose={toggleDrawer}
        visible={drawerOpen}
      >
        <NavBar drawer modalOpen={() => setDrawerOpen(false)} closeModal={closeModal} />
      </Drawer>
      <Header
        hidden={page === 'createCourseSchool'}
        className={[
          styles.header,
          fixed ? styles.fixed : null,
          fixed && transparent ? styles.transparent : null,
          page === 'search' ? styles.search : null,
          page === 'index' ? styles.normalHeader : null,
        ].join(' ')}
      >
        <Link
          route="index"
          className={[styles.logo, page !== 'index' ? `${styles[transitionLogo()]}` : null].join(
            ' ',
          )}
          style={{
            backgroundImage: `url(${logoUrl})`,
            width: domain === 'course.am' || domain === 'localhost:3000' ? 156 : 196,
          }}
        >
          <span id="logo" />
        </Link>

        {page !== 'index' && <Search placement="header" handleFocusChange={handleFocusChange} />}

        <NavBar closeModal={closeModal} />

        <button
          type="button"
          className={`${styles[transitionDrawerButton()]}`}
          onClick={toggleDrawer}
        >
          <img src="/static/images/menu.png" alt="menu" />
        </button>
      </Header>
    </>
  );
};

export default CustomHeader;
