import React from 'react'
import { Popover, Avatar } from 'antd';
import dynamic from 'next/dynamic';
import { LocalizationContext } from 'hoc/Localization';
import { SettingsContext } from 'hoc/Settings';
import { UserContext } from 'hoc/User';
import { SecurityContext } from 'hoc/Security';
import Link from '../../Link';
import Router from '../../../routes';
import styles from './styles.less';

const Profile = dynamic(() => import('../Profile'));
const MySchool = dynamic(() => import('./MySchool'));

interface NavBarProps {
    drawer: boolean,
    closeModal: () => any
}

export default ({ drawer = false, closeModal = () => { } }: NavBarProps): JSX.Element => {
    const { locale, t } = React.useContext(LocalizationContext);
    const { user, logout } = React.useContext(UserContext);
    const { setVisible } = React.useContext(SecurityContext);
    // eslint-disable-next-line no-unused-vars
    const { wizardVisible, setWizardVisible } = React.useContext(SecurityContext);
    const {
        // eslint-disable-next-line camelcase
        domainSettings: { featureCommunity, showBlog, feature_enableGuidePaths },
        // isRegisterRewardEnabledAndConfigured
    } = React.useContext(SettingsContext);

    const createCourseProps: { route: string, className: string, school?: any } = {
        route: 'createCourse',
        className: styles.active,
    };

    if (user && user.schools && user.schools.length === 1 && user.schools[0]) {
        createCourseProps.school = user.schools[0].shortId;
    }
    const addCourseHandler = () => {
        setWizardVisible(false);
        closeModal();
        if (user && user.schools && user.schools[0]) {
            return Router.pushRoute('createCourseSchool', {
                locale,
                step: 1,
                shortId: user.schools[0].shortId,
            });
        }
        return Router.pushRoute('addCourseIntro', {
            locale,
        });
    };
    const link = <a onClick={() => setWizardVisible(true)}>{t('details.edit.courses.add')}</a>;

    return (
        <>
            <nav className={drawer ? `${styles.menu} ${styles.verticalMenu}` : styles.menu}>
                <div className={styles.addCourseContainer} onClick={addCourseHandler}>
                    {link}
                </div>
                {!drawer && !!user && !!user.schools && !!user.schools.length && (
                    <Popover
                        placement="bottom"
                        content={<MySchool closeModal={closeModal} createCourseProps={createCourseProps} />}
                    >
                        <a className='menu-my-school'>{t('menu.mySchool')}</a>
                    </Popover>
                )}

                {/* eslint-disable-next-line camelcase */}
                {feature_enableGuidePaths && (
                    <Link route="paths" onClick={() => closeModal()}>{t('home.header.paths')}</Link>
                )}

                {drawer && (
                    <>
                        {user && (
                            <>
                                <div className={styles.user}>
                                    <Avatar src={user.picture} />
                                    <span className={styles.userName}>{user.fullName}</span>
                                </div>

                                {!!user.schools && !!user.schools.length && (
                                    <div className={styles.section}>
                                        <Popover
                                            trigger="focus"
                                            content={
                                                <MySchool createCourseProps={createCourseProps} closeModal={closeModal} />
                                            }
                                        >
                                            <button type="button" className={styles.buttonInDrawer}>
                                                {t('menu.mySchool')}
                                            </button>
                                        </Popover>
                                        {/* {isRegisterRewardEnabledAndConfigured && (
                                            <Link className={styles.link} route="rewards" onClick={() => closeModal()}>
                                            <span id="rewards">{t('menu.rewards')}</span>
                                            </Link>
                                        )} */}
                                        {!!user.testResultHash && (
                                            <Link className={styles.link} route="profile" onClick={() => closeModal()}>
                                                <span id="profile">{t('menu.profile')}</span>
                                            </Link>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                        <div className={styles.section}>
                            {featureCommunity && (
                                <a
                                    onClick={() => closeModal()}
                                    href="https://course.am/community"
                                    rel="noopener noreferrer"
                                >
                                    {t('header.menu.forum')}
                                </a>
                            )}
                            {showBlog && (
                                <Link route="blog" onClick={() => closeModal()}>
                                    {t('footer.blog')}
                                </Link>
                            )}
                            {user && (<>
                                <Link className={styles.link} route="orders" onClick={() => closeModal()}>
                                    <span id="orders">{t('menu.orders')}</span>
                                </Link>
                                <Link className={styles.link} route="favourites" onClick={() => closeModal()}>
                                    <span id="favourites">{t('menu.favourites')}</span>
                                </Link>
                            </>)}
                        </div>
                    </>
                )}

                {!drawer && (
                    <>
                        {featureCommunity && (
                            <a href="https://course.am/community" rel="noopener noreferrer">
                                {t('header.menu.forum')}
                            </a>
                        )}
                        {showBlog && <Link route="blog">{t('footer.blog')}</Link>}
                    </>
                )}

                {!user ? (
                    <div
                        id="login"
                        style={{ height: '100%' }}
                        onClick={() => {
                            setVisible(true);
                            closeModal();
                        }}
                    >
                        <a>{t('menu.login')}</a>
                    </div>
                ) : (
                        (!drawer && <Profile />) || (
                            <div className={styles.section} onClick={() => closeModal()}>
                                <a onClick={logout}>{t('menu.logout')}</a>
                            </div>
                        )
                    )}
            </nav>
        </>
    );
}