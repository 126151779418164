import React from 'react';
import PropTypes from 'prop-types';
import App from 'next/app';
import Head from 'next/head';
import TagManager from 'react-gtm-module';
import { LocalizationContext, LocalizationProvider } from '../hoc/Localization';
import { SettingsProvider } from '../hoc/Settings';
import { LocationProvider } from '../hoc/Location';
import { SearchProvider } from '../hoc/Search';
import { EditorProvider } from '../hoc/Editor';
import { RouteProvider } from '../hoc/Route';
import { UserProvider } from '../hoc/User';
import Analytics from '../hoc/Analytics';
import Layout from '../components/Layout';
import Error from './_error';
import bugsnagClient from '../utils/bugsnag';
import { getSyncUser } from '../utils';
import { SecurityProvider } from '../hoc/Security';

const gtmId = process.env.GOOGLE_TAG_MANAGER_ID;

const ErrorBoundary = bugsnagClient.getPlugin('react');

const Page = ({ children }) => {
  const { t } = React.useContext(LocalizationContext);

  return (
    <>
      <Head>
        <title>{t('meta.title.suffix')}</title>
      </Head>
      {children}
    </>
  );
};

Page.propTypes = {
  children: PropTypes.any.isRequired,
};

class Application extends App {
  // @TODO have some checks regarding the following NOTE about getInitialProps function we I bring here from docs
  // Only uncomment this method if you have blocking data requirements for
  // every single page in your application. This disables the ability to
  // perform automatic static optimization, causing every page in your app to
  // be server-side rendered.
  //
  static async getInitialProps({ Component, ctx, router }) {
    process.ctx = ctx;

    this.cache = this.cache || {};

    const [locationProps, settingsProps, routeProps, userProps, searchProps] = await Promise.all([
      LocationProvider.getInitialProps(ctx, router),
      SettingsProvider.getInitialProps(ctx, router),
      RouteProvider.getInitialProps(ctx, router),
      UserProvider.getInitialProps(ctx, router),
      SearchProvider.getInitialProps(ctx, router),
    ]);

    const localizationProps = await LocalizationProvider.getInitialProps(ctx, settingsProps);

    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx, router, {
        locationProps,
        settingsProps,
        localizationProps,
        routeProps,
        userProps,
        searchProps,
      });
    }

    return {
      pageProps,
      routeProps,
      settingsProps,
      locationProps,
      localizationProps,
      userProps,
      searchProps,
    };
  }

  componentDidMount() {
    TagManager.initialize({
      gtmId,
    });
  }

  /* eslint-disable */
  beforeReportSend(report) {
    report.user = getSyncUser();
  }
  /* eslint-unable */

  render() {
    const {
      Component,
      pageProps,
      routeProps,
      locationProps,
      settingsProps,
      localizationProps,
      userProps,
      searchProps,
    } = this.props;

    process.ELASTIC_URL = process.browser
      ? `${process.env.NODE_ENV === 'production' ? 'https' : 'http'}://${
          settingsProps.domainSettings.esHost || settingsProps.domainSettings.domain
        }${process.env.NODE_ENV === 'production' ? '' : ':3000'}/`
      : null;

    return (
      <ErrorBoundary FallbackComponent={Error} beforeSend={this.beforeReportSend}>
        <RouteProvider {...routeProps}>
          <Analytics>
            <LocationProvider {...locationProps}>
              <SettingsProvider {...settingsProps}>
                <LocalizationProvider {...localizationProps}>
                  <UserProvider {...userProps}>
                    <SearchProvider {...searchProps}>
                      <SecurityProvider>
                        <EditorProvider>
                          {/* <EditorWizardProvider> */}
                          <Head>
                            <meta
                              name="viewport"
                              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                            />
                          </Head>

                          <Layout>
                            <Page>
                              <Component {...pageProps} />
                            </Page>
                          </Layout>
                          {/* </EditorWizardProvider> */}
                        </EditorProvider>
                      </SecurityProvider>
                    </SearchProvider>
                  </UserProvider>
                </LocalizationProvider>
              </SettingsProvider>
            </LocationProvider>
          </Analytics>
        </RouteProvider>
      </ErrorBoundary>
    );
  }
}

export default Application;
